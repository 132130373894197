import {
  cache,
  createAsync,
  useNavigate,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import { Show } from "solid-js";
import PlutoGiftDetailsComponent from "~/components/gifting/pluto-receiver/pluto-receiver-gift-details/pluto_receiver_gift_details";
import PlutoGiftDetailsRouteData from "~/server/data/personalised_gifting/pluto_gift_details_route_data";

export default function PlutoRedeemRoute() {
  const [queryParams, setQueryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  const amount = Number(queryParams.amount ?? "5000");

  const PlutoRedeeemRouteData$C = cache(
    PlutoGiftDetailsRouteData,
    "PlutoGiftDetailsRouteData"
  );
  const params = useParams();
  const giftingKey = params.giftingKey;

  const navigate = useNavigate();

  const routeData = createAsync(
    async () => {
      return await PlutoRedeeemRouteData$C({
        giftingKey: giftingKey,
        isPreview: isPreview,
        amount: amount,
      });
    },
    { deferStream: true }
  );

  return (
    <Show when={routeData()}>
      <PlutoGiftDetailsComponent
        giftingKey={giftingKey}
        routeData={routeData()!}
        onClickBrand={(brandId: string) => {
          let link = `/pluto/reveal/${giftingKey}/buy/${brandId}`;
          if (isPreview) {
            link += "?isPreview=true";
            if (amount) {
              link += `&amount=${amount}`;
            }
          }
          navigate(link);
        }}
      />
    </Show>
  );
}
