import { coinIcon } from "~/assets/assets";
import { PlutoRedeeemRouteDataResponse } from "~/server/data/personalised_gifting/pluto_gift_details_route_data";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";
import PlutoBranding from "../pluto_branding";
import { DividerV2 } from "~/widgets/divider";
import { A } from "@solidjs/router";
import {
  BackButtonReceiver,
  buildBgGradient,
} from "../pluto_receiver_components";
import { Show } from "solid-js";
import { env } from "~/data/config";

export type PlutoRedeemComponentProps = {
  giftingKey: string;
  routeData: PlutoRedeeemRouteDataResponse;
  onClickBrand: (brandId: string) => void;
};

export default function PlutoGiftDetailsComponent(
  props: PlutoRedeemComponentProps
) {
  const data = props.routeData;
  return (
    <div class="relative flex min-h-screen flex-col overflow-y-scroll sm:items-center">
      {buildBgGradient()}
      <div class="no-scrollbar flex flex-col items-start justify-start  bg-black px-5 text-baseTertiaryLight sm:max-w-[343px] sm:px-0">
        <Spacer height={20} />
        <div class="z-20 flex  w-full flex-row items-center justify-between">
          <BackButtonReceiver />
          <Show when={props.routeData.isRtu}>
            {buildMyGiftCardsButton(props.giftingKey)}
          </Show>
        </div>

        <Spacer height={20} />
        {buildTitle()}
        <Spacer height={40} />
        {buildSubtitle()}
        <Spacer height={40} />
        {buildGiftBalance()}
        <Spacer height={40} />
        <Heading>{"Experiences curated for you"}</Heading>
        <Spacer height={20} />
        {buildBrandCards()}
        <Spacer height={40} />
        <Heading>{"here is how it works"}</Heading>
        <Spacer height={20} />
        {footerText()}
        <Spacer height={40} />
        <DividerV2 class="bg-basePrimaryDark" />
        <Spacer height={16} />
        <PlutoBranding />
      </div>
    </div>
  );

  function buildMyGiftCardsButton(giftingKey: string) {
    return (
      <A href={`/pluto/reveal/${giftingKey}/my-gift-cards`}>
        <div class="rounded-full border border-[#333232] bg-black px-4 py-[9px] text-mediumBold text-[#EDEEF1]">
          My gift cards
        </div>
      </A>
    );
  }

  function buildTitle() {
    return <span class=" text-h3 ">{data.heading}</span>;
  }

  function buildSubtitle() {
    return (
      <span class="text-normal text-textNormal">
        <TextWithLineBreaks>{data.description}</TextWithLineBreaks>
      </span>
    );
  }

  function buildGiftBalance() {
    return (
      <div class="flex flex-col items-start">
        <span class="text-smallBold">GIFT BALANCE</span>
        <Spacer height={8} />
        <div class=" flex flex-row items-center justify-start">
          <img src={coinIcon} class="h-[20.9px] w-[20.9px]" alt="Coin icon" />
          <Spacer width={4} />
          <span class="text-h4">{data.balance}</span>
          <Spacer width={4} />
          <span class="pt-[2px] text-medium text-baseSecondaryLight">{`/${data.totalAmount} remaining`}</span>
        </div>
      </div>
    );
  }

  function buildBrandCards() {
    return (
      <div class="flex flex-col gap-4">
        {data.brands.map((brand) => {
          return (
            <div
              onClick={() => props.onClickBrand(brand.brandId)}
              class="relative flex min-h-4 cursor-pointer flex-col items-start gap-3 rounded-xl bg-basePrimaryDark p-4"
            >
              <img
                src={brand.imageUrl}
                class="h-[80px] w-[80px] rounded-full"
              ></img>
              <span class="text-h4 font-medium">{brand.heading}</span>
              <span class="text-medium text-textNormal">
                {brand.subHeading}
              </span>
              <div class="absolute right-4 top-4">
                <PhosphorIcon
                  name="caret-right"
                  class="text-baseTertiaryLight"
                  fontSize={16}
                ></PhosphorIcon>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function footerText() {
    return (
      <span class="text-normal text-baseSecondaryLight">
        {getFooterMessage()}
      </span>
    );
  }
}

function Heading(props: { children: string }) {
  return (
    <div class="flex w-full flex-row items-center gap-2">
      <span class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
        {props.children}
      </span>
      <div class="h-[1px] w-full bg-basePrimaryMedium"></div>
    </div>
  );
}

function TextWithLineBreaks(props: { children: string }) {
  const textWithBreaks = props.children.split("\n").map((text, index) => (
    <>
      {text}
      <br />
    </>
  ));
  return <div>{textWithBreaks}</div>;
}

function getFooterMessage() {
  return (
    <span>
      1. Pick the experience you want to go for.
      <br />
      2. Get a gift card of respective brand using the gift balance.
      <br />
      3. Use gift card to pay for the experience / product / service of that
      brand.
      <br />
      <br />
      I hope you enjoy it! <br />
      <br />
      By the way, I’m Pluto—India’s first AI-powered thoughtful gifting
      assistant. I can help you create personalized gifts just like the one you
      have received. Want to give me a try?
      <br />
      <u
        onClick={() => {
          if (env === "Staging") {
            window.open(
              "https://vouchers.dev.myhubble.money/pluto/create/new",
              "_blank"
            );
          } else {
            window.open(
              "https://gifting.myhubble.money/pluto/create/new",
              "_blank"
            );
          }
        }}
        class="cursor-pointer underline-offset-2 "
      >
        Click here.
      </u>
      <br />
      <br />
      Cheers,
      <br />
      Pluto
    </span>
  );
}
